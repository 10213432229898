import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'react-async';
import { Alert, Form, Container, Spinner, Button, Row, Col } from 'react-bootstrap';
import Attributes from './Attributes.js';
import PrivacyPolicy from './PrivacyPolicy.js';


function PrivacyForm(props) {
  const { t, i18n } = useTranslation();
  const lang = i18n.languages[0];

  const data = props.data
  const ticketId = props.ticketId
  const [agreed, setAgreed] = useState(data.hasOwnProperty("policy"))

  const terms = useRef({})
  const policy = useRef({})

  const onTermsChange = (agreed) => {}

  const onPolicyChange = (agreed) => {
    setAgreed(agreed)
  }


  const handleSubmit = (event) => {
    event.preventDefault()

    var params = { ticket_id: ticketId, lang: lang }
    params.agreed_terms = { url : data.terms.url, commit_id : data.terms.commit_id, checksum : data.terms.checksum }
    params.agreed_policy = { url : data.policy.url, commit_id :data.policy.commit_id, checksum : data.policy.checksum }
    console.log(JSON.stringify(params))

    submitState.run({
      headers: { "Content-Type" : "application/json" },
      body: JSON.stringify(params)
    })
  }

  const onSubmitted = (consent_data) => {
    window.location.href = data.redirect_endpoint
  }

  const submitState = useFetch("/consent", { "method" : "POST" }, { onResolve : onSubmitted })



  return (
    <Container>
        <h1 style={{marginTop: "0.8em", marginBottom: "0.8em"}}>{t("privacyform-consent")} "{data.requester}"</h1>
        <Form onSubmit={handleSubmit}>
        <Attributes attributes={data.attributes} />
        { data.hasOwnProperty("terms") && Object.keys(data.terms).length ? <PrivacyPolicy label={t("privacyform-terms")} policy={data.terms.content} checkbox={false} onChange={onTermsChange} /> : ""}
        { data.hasOwnProperty("policy") && Object.keys(data.policy).length? <PrivacyPolicy label={t("privacyform-policy")} policy={data.policy.content} checkbox={true} onChange={onPolicyChange} /> : "" }
        <Row>
            <Col sm="2"></Col>
            <Col sm="10">
            {submitState.error && <Alert variant="danger">{t("privacyform-error-submitting")}: {submitState.error.message}</Alert>}
            <Button variant="primary" size="lg" disabled={agreed && !submitState.isPending ? false : true} type="submit">{ submitState.isPending ? <Spinner animation="border" /> : t("privacyform-submit")}</Button>
            </Col>
        </Row>
        </Form>
    </Container>
  )
}

export default PrivacyForm
